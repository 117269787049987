<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow
    backdrop no-header right @change="val => $emit('update:is-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t('Add New') : $t('Update') }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>

            <b-col cols="12">
              <!-- prefixEmail -->
              <b-form-group :label="$t('Prefix Email')" label-for="prefixEmail">
                <validation-provider #default="{ errors }" name="prefixEmail" vid="prefixEmail" rules="required">
                  <b-form-input id="prefixEmail" v-model="prefixEmail" name="prefixEmail"
                    :state="errors.length > 0 ? false : null" placeholder="@ibig.dev" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- Agency -->
              <b-form-group :label="$t('Agency')" label-for="register-agency">
                <validation-provider #default="{ errors }" name="Agency" vid="agency" :rules="''">
                  <v-select v-model="agency" chips multiple size="sm" label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="agencyData"
                    :reduce="agencyData => agencyData._id" :clearable="true" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- Agency -->
              <b-form-group :label="$t('Department')" label-for="register-department">
                <validation-provider #default="{ errors }" name="Department" vid="department" :rules="''">
                  <v-select v-model="department" chips multiple size="sm" label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="departmentData"
                    :reduce="departmentData => departmentData._id" :clearable="true" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" @click="validationForm()">
              {{ $t('Submit') }}
            </b-button>
            <b-button type="button" variant="outline-secondary" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import store from '@/store'

export default {
  components: {
    BSidebar,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      prefixEmail: '',
      agency: [],
      department: [],
      required,
    }
  },
  computed: {
    agencyData() {
      return store.state.agency.respData != null
        ? store.state.agency.respData.data.map(e => {
          const indexLang = e.name.findIndex(
            name => name.lang === this.$i18n.locale,
          )
          if (indexLang > -1) {
            return {
              _id: e._id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            name: e.name[0].value,
          }
        })
        : []
    },
    departmentData() {
      return store.state.department.respData != null
        ? store.state.department.respData.data.map(e => {
          const indexLang = e.name.findIndex(
            name => name.lang === this.$i18n.locale,
          )
          if (indexLang > -1) {
            return {
              _id: e._id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            name: e.name[0].value,
          }
        })
        : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id,
            prefix_email,
            agency,
            department,
          } = this.data
          this.dataId = _id
          // eslint-disable-next-line camelcase
          this.prefixEmail = prefix_email
          this.agency = agency
          this.department = department
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.prefixEmail = ''
      this.agency = []
      this.department = []
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            dataId: this.dataId,
            prefixEmail: this.prefixEmail,
            agency: this.agency,
            department: this.department,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/updateConfig`, obj)
              .then(result => {
                this.initValues()
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.userId
            store
              .dispatch(`${this.storeModuleName}/addConfig`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
