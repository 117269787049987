var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(Object.entries(_vm.data).length === 0 ? _vm.$t('Add New') : _vm.$t('Update'))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"formData"},[_c('b-form',{staticClass:"p-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Prefix Email'),"label-for":"prefixEmail"}},[_c('validation-provider',{attrs:{"name":"prefixEmail","vid":"prefixEmail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"prefixEmail","name":"prefixEmail","state":errors.length > 0 ? false : null,"placeholder":"@ibig.dev"},model:{value:(_vm.prefixEmail),callback:function ($$v) {_vm.prefixEmail=$$v},expression:"prefixEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Agency'),"label-for":"register-agency"}},[_c('validation-provider',{attrs:{"name":"Agency","vid":"agency","rules":''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"chips":"","multiple":"","size":"sm","label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.agencyData,"reduce":function (agencyData) { return agencyData._id; },"clearable":true},model:{value:(_vm.agency),callback:function ($$v) {_vm.agency=$$v},expression:"agency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Department'),"label-for":"register-department"}},[_c('validation-provider',{attrs:{"name":"Department","vid":"department","rules":''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"chips":"","multiple":"","size":"sm","label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.departmentData,"reduce":function (departmentData) { return departmentData._id; },"clearable":true},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }